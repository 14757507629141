@import "../_config";
.breadcrumbs {
    margin-left: 190px;
    width: 100%;
    display: table;
    font-size: 0;
    line-height: 60px;
    height: 60px;
    margin-bottom: 20px;
    > span {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        line-height: 18px;
        margin-right: 7px;
        color: #000;
        &:before {
            content: '/';
            display: inline-block;
            vertical-align: middle;
            margin-right: 7px;
        }
        &:first-child:before {
            display: none;
        }
        > a {
            color:$colorOrange;
            text-decoration: underline;
            &:hover{text-decoration: none;}
        }
    }
}
@media only screen and (max-width:620px) {
    .breadcrumbs {
        display: block;
        > span {
            display: none;
            &:before {
                display: none;
            }
            &:nth-last-child(2) {
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;
                background: none;
                margin: 0;
                &:before {
                    content: '<';
                    display: inline-block;
                    margin: 0 5px 0 3px;
                }
            }
        }
    }
}
