@import "../_config";
$lineHeight: $emSize * 2 + $unit * 2;

$boxShadowBtn: inset 0 (-$unit * 2) $unit * 3 rgba($colorWhite, .45), inset 0 $unit * 2 $unit * 3 rgba($colorBlack, .35), 0 $unit * 2 0;

$height: $lineHeight - $unit;
$padding: 30px;

$svgHeight: $emSize + $unit * 1.5;
$svgWidth: $svgHeight;
$svgMargin: -$unit $emSizeHalf - $unit 0 0;

$borderRadius:0;


@mixin Shadow($shadow) {
    box-shadow: $boxShadowBtn $shadow;
}

@mixin Button($a, $t) {
    $b: darken($a, 6.5%);
    $c: darken($b, 15%);
    $s: darken($c, 4%);
    background: $a;
    background: -moz-linear-gradient(top, $a 0%, $b 100%);
    background: -webkit-linear-gradient(top, $a 0%, $b 100%);
    background: linear-gradient(to bottom, $a 0%, $b 100%);
    &:hover {
        color: $t;
        background: lighten($a, 0.5%);
    }
    &:active {
        color: $t;
        top: $unit;
    }
}

.wBtn {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: auto;
    font-size:13px;
    height: 36px;
    line-height: 1.2em;
    border: none;
    transition: transform 3s linear;
    border-radius: $borderRadius;
    padding: 0 $padding;
    margin: 0 0 $emSize / 2.5;
    cursor: pointer;
    color: $colorMiddleDark;
    text-shadow: $unit $unit 0 $colorWhite;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    @include Button($colorDefault, $colorDark);
    > .svgHolder {
        margin: $svgMargin;
        text-decoration: none !important;
        display: inline-block;
        vertical-align: middle;
        height: $svgHeight;
        width: $svgWidth;
        > svg {fill: $colorMiddleDark; }
    }
    &.w_block {
        display: block;
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
    &.w_rightIcon {
        > .svgHolder {
            float: right;
            margin: $emSizeHalf + $unit 0 0 $emSizeHalf - $unit;
        }
    }
    &:hover, &:active {
        > .svgHolder > svg {@extend %wBtnSvg; }
    }
    &.w_primary {
        @extend %wBtnStatus;
        @include Button($colorPrimary, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_success {
        @extend %wBtnStatus;
        @include Button($colorOrange, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_info {
        @extend %wBtnStatus;
        @include Button($colorInfo, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_warning {
        @extend %wBtnStatus;
        @include Button($colorWarning, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_danger {
        @extend %wBtnStatus;
        @include Button($colorDanger, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_large {
        font-size: $fontSize * 1.25;
    }
}
%wBtnSvg {fill: $colorDark;}
%wBtnStatus {
    color: $colorWhite;
    text-shadow: 0 1px 3px rgba($colorBlack,.3);
    > .svgHolder > svg {fill: $colorWhite;}
}
%wBtnSvgWhite {fill: $colorWhite;}
