// единицы измерения
$emSize: 1em;
$emSizeDouble: $emSize * 2;
$emSizeText: $emSize * 1.5;
$emSizeHeader: $emSize * 1.2;
$emSizePart: $emSize * 0.7;
$emSizeHalf: $emSize * 0.5;
$emSizeUn3: $emSize * 0.3;
$emSizeUn2: $emSize * 0.2;

// шрифты
$fontFamily: Roboto,Arial, Helvetica, sans-serif;
$fontSize: 14px;
$fontSizeH1: 40px;

$unit: $emSize / 14;

// цвета
$colorBlack: #000;
$colorDark: #363636;
$colorMiddleDark: #666;
$colorMiddle: #999;
$colorWhite: #fff;
$colorLight: #eee;
$colorMiddleLight: #ddd;

$colorYellow: #ff0;
$colorOrange: #ffaa25;
$colorRed: #f00;

$colorDefault: #fafafb;
$colorPrimary: #0295d5;
$colorSucces: #78BE30;
$colorInfo: #74BBFF;
$colorWarning: #4ADB4A;
$colorDanger: #EB4F1F;

$rgbaDark: rgba($colorBlack, 0.3);

// текст
$colorText: $colorBlack;
$textShadowDark: 0 1px 2px $rgbaDark;

// ссылки
$colorLink: #43f;
$colorLinkVisited: #609;
$colorLinkHover: #f00;
$colorActive: $colorText;

// border
$borderRadius: $emSize * 0.25;
$borderColor: $colorMiddleLight;
$border: 1px solid $borderColor;
$borderTableColor: $colorMiddleDark;
$borderTable: 1px solid $borderTableColor;

// футер

$footerSize: 275px;

// миксины
@mixin HVcenter($w, $h) {
	display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: $w;
    max-height: $h;
    margin: auto;
}
